import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { Button } from 'components/button-new';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { bool, func, node, string } from 'prop-types';

const SContainer = styled.div`
    max-width: 32.25rem;
    height: 26.5rem;
    padding: 3.75rem 3.625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
`;

const SIconWrapper = styled.div`
    margin-bottom: 1.5rem;
`;

const SHeaderSecond = styled(HeaderSecond)`
    font-weight: 400;
    font-size: 1.75rem;
    margin-bottom: 0.75rem;
`;

const paragraphStyles = css`
    font-size: 1rem;
    color: var(--gray-color-80);
`;

const SFirstParagraph = styled(Paragraph)`
    ${paragraphStyles};
    margin-bottom: ${({ margin }) => (margin ? '1.5rem' : 0)};
`;

const SSecondParagraph = styled(Paragraph)`
    ${paragraphStyles};
    margin-bottom: 2.875rem;
`;

export const DialogContent = ({
    icon,
    title,
    info,
    description,
    action,
    onClose,
    firstParagraphMargin,
}) => {
    return (
        <SContainer>
            <SIconWrapper>{icon}</SIconWrapper>
            <SHeaderSecond>
                <FormattedMessage id={title} />
            </SHeaderSecond>
            <SFirstParagraph margin={firstParagraphMargin}>
                <FormattedMessage id={info} />
            </SFirstParagraph>
            {description && (
                <SSecondParagraph>
                    <FormattedMessage id={description} />
                </SSecondParagraph>
            )}
            <Button testId="form_popup_button" onClick={onClose}>
                <FormattedMessage id={action} />
            </Button>
        </SContainer>
    );
};

DialogContent.propTypes = {
    icon: node.isRequired,
    title: string.isRequired,
    info: string.isRequired,
    onClose: func.isRequired,
    firstParagraphMargin: bool,
    description: string,
};

DialogContent.defaultProps = {
    firstParagraphMargin: false,
};
