import { useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const supportedBrowsers = [
    'Chrome',
    'Firefox',
    'Safari',
    'Edge',
    'IE (untill 15.06.2022)',
    'Android native browser',
];

export const useReCaptcha = ({ isDirty, onFailedLoad }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    // const executeRecaptcha = undefined;

    useEffect(() => {
        if (isDirty && !executeRecaptcha) {
            onFailedLoad();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty, executeRecaptcha]);

    const requestRecaptchaValidation = useCallback(async () => {
        if (!executeRecaptcha) {
            throw new Error(
                `Execution of reCaptcha is not available yet. Check if you are using supported browser. Supported browsers: ${supportedBrowsers.join(
                    ', ',
                )}.`,
            );
        }

        const token = await executeRecaptcha('dynamicAction');
        return token;
    }, [executeRecaptcha]);

    return { requestRecaptchaValidation };
};
