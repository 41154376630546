import { FORM_STATUSES } from 'constants/form-statuses';

import { DIALOG_CONTENT } from '../constants';

export const resolveDialogContent = ({ formStatus, customDialogContent }) => {
    switch (formStatus) {
        case FORM_STATUSES.SUCCESS:
            return customDialogContent?.success ?? DIALOG_CONTENT.SUCCESS;
        case FORM_STATUSES.ERROR:
            return customDialogContent?.error ?? DIALOG_CONTENT.ERROR;
        case FORM_STATUSES.RECAPTCHA_SCRIPT_NOT_LOADED_ERROR:
            return (
                customDialogContent?.recaptchaError ??
                DIALOG_CONTENT.RECAPTCHA_SCRIPT_NOT_LOADED_ERROR
            );
        default:
            return DIALOG_CONTENT.SUCCESS;
    }
};
