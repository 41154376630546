import React from 'react';
import SuccessIcon from 'svgs/icons/ic-check-green.svg';
import FailIcon from 'svgs/icons/ic-close-rounded.svg';

const path = 'general.dialog';
export const DIALOG_CONTENT = {
    SUCCESS: {
        title: `${path}.success.title`,
        info: `${path}.success.messageHasBeenSent`,
        description: `${path}.success.salesContactYou`,
        action: 'general.ok',
        icon: <SuccessIcon />,
    },
    ERROR: {
        title: `${path}.error.title`,
        info: `${path}.error.info`,
        description: `${path}.error.description`,
        action: 'general.ok',
        icon: <FailIcon />,
    },
    RECAPTCHA_SCRIPT_NOT_LOADED_ERROR: {
        title: `${path}.error.title`,
        info: `${path}.error.recaptchaInfo`,
        description: `${path}.error.recaptchaDescription`,
        action: `${path}.error.recaptchaAction`,
        icon: <FailIcon />,
    },
};
