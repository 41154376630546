import { API_URL } from '../constants/api-url';

import { requestConstructor } from './helpers';

export const sendContactEmail = async (body) => {
    const response = await requestConstructor(API_URL.MAIL, body);
    return response;
};

export const sendFooterContactEmail = async (body) => {
    const response = await requestConstructor(API_URL.FOOTER_MAIL, body);
    return response;
};

export const sendLandingPageEmail = async (body) => {
    const response = await requestConstructor(API_URL.LANDING_PAGE_MAIL, body);
    return response;
};

export const contactExpert = async (body) => {
    const response = await requestConstructor(API_URL.MAILBOX, body);
    return response;
};
