import { DIALOG_CONTENT } from 'components/dialog/constants';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { GTAG_CONSTANTS } from 'constants/gtag-constants';

export const CONTACT_DATA = [
    {
        title: 'footer.mobileReality',
        paragraphs: [
            { text: 'footer.address' },
            { text: 'footer.city' },
            { text: 'footer.vatId' },
            { text: 'footer.regon' },
        ],
    },
    {
        title: 'footer.europeanUnion',
        paragraphs: [
            {
                text: 'footer.ueNumber',
                type: 'tel',
                isCopyButton: true,
                googleAnalyticsCopy:
                    gaTrackedEvents.CONTACT_PAGE.CTA.COPY_ACTION,
                googleAnalytics: gaTrackedEvents.CONTACT_PAGE.CTA.CALL_ACTION,
            },
            {
                text: 'emails.office',
                type: 'mailto',
                isCopyButton: true,
                googleAnalyticsCopy:
                    gaTrackedEvents.CONTACT_PAGE.CTA.COPY_ACTION,
                googleAnalytics: gaTrackedEvents.CONTACT_PAGE.CTA.MAIL_ACTION,
            },
        ],
    },
    {
        title: 'footer.northAmerica',
        paragraphs: [
            {
                text: 'footer.usNumber',
                type: 'tel',
                isCopyButton: true,
                googleAnalyticsCopy:
                    gaTrackedEvents.CONTACT_PAGE.CTA.COPY_ACTION,
                googleAnalytics: gaTrackedEvents.CONTACT_PAGE.CTA.CALL_ACTION,
            },
            {
                text: 'emails.office',
                type: 'mailto',
                isCopyButton: true,
                googleAnalyticsCopy:
                    gaTrackedEvents.CONTACT_PAGE.CTA.COPY_ACTION,
                googleAnalytics: gaTrackedEvents.CONTACT_PAGE.CTA.MAIL_ACTION,
            },
        ],
    },
];

export const customDialogContent = {
    error: {
        ...DIALOG_CONTENT.ERROR,
        info: 'contact-us-page.dialog.error',
        description: '',
    },
};
