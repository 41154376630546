import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';

const SDialog = styled.dialog`
    margin: auto;
    border-radius: 1.8rem;

    &::backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: hsl(0 0% 10% / 0.5);
    }
`;

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--white-color);
`;

export const Dialog = forwardRef((props, ref) => {
    const { children, testId, ...rest } = props;
    const dialogRef = useRef(null);

    useImperativeHandle(ref, () => ({
        show: () => {
            dialogRef.current?.show();
        },
        showModal: () => {
            dialogRef.current?.showModal();
        },
        close: () => {
            dialogRef.current?.close();
        },
    }));

    return (
        <SDialog
            ref={dialogRef}
            {...rest}
            {...(testId && { 'data-testid': testId })}
        >
            <SContainer>{children}</SContainer>
        </SDialog>
    );
});

Dialog.displayName = 'Dialog';
