import React from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import { Paragraph } from 'components/paragraph';
import { bool, func, node, object, oneOfType, string } from 'prop-types';

const SCheckbox = styled.input`
    appearance: none;
    width: 1rem;
    height: 1rem;
    border-radius: 0.25rem;
    border: 1px solid var(--gray-color);
    cursor: pointer;
    margin-right: 1rem;

    &:checked {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.838' height='8.651' viewBox='0 0 8.838 8.651'%3E%3Cpath id='Path_2197' data-name='Path 2197' d='M-18725.863,2578.032-18723,2580l4.582-6.695' transform='translate(18726.43 -2572.741)' fill='none' stroke='%23f53103' stroke-width='2'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        border-color: var(--primary-color);
    }
`;

const SLabel = styled.label`
    flex: 1;
    font-size: 0.875rem;
`;

const SError = styled(Paragraph)`
    font-size: 0.75rem;
    color: var(--primary-color);
    margin-top: 0.5rem;
    padding: 0 1rem;
`;

const SRow = styled.div`
    display: flex;
    align-items: center;
`;

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const SSpan = styled.span`
    color: var(--primary-color);
`;

const SIconWrapper = styled.span`
    margin-right: 1rem;
    display: flex;
`;

export const Checkbox = ({
    name,
    control,
    label,
    error,
    disabled,
    className,
    isRequired,
    onChange,
    icon,
}) => {
    const {
        field: { value, onChange: onFormChange },
    } = useController({
        name,
        control,
    });

    const handleChange = (e) => {
        onFormChange(!value);
        onChange?.(e);
    };

    return (
        <SContainer className={className}>
            <SRow>
                <SCheckbox
                    id={name}
                    type="checkbox"
                    disabled={disabled}
                    checked={value}
                    aria-required={isRequired}
                    onChange={handleChange}
                />
                {icon && (
                    <SIconWrapper className="icon-wrapper">{icon}</SIconWrapper>
                )}
                <SLabel htmlFor={name}>
                    {label} {isRequired && <SSpan aria-hidden={true}>*</SSpan>}
                </SLabel>
            </SRow>
            {error && <SError>{error?.message}</SError>}
        </SContainer>
    );
};

Checkbox.propTypes = {
    name: string.isRequired,
    control: object.isRequired,
    label: oneOfType([string, node]).isRequired,
    error: object,
    className: string,
    disabled: bool,
    isRequired: bool,
    icon: node,
    onChange: func,
};

Checkbox.defaultProps = {
    className: '',
    disabled: false,
    isRequired: false,
};
