import React from 'react';
import { ContactUsView } from 'views/contact-us';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/contact-us.json';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const ContactUsPage = (props) => {
    const { location } = props;
    return (
        <Layout location={location} messages={messages} navbar={false} fullPage>
            <ContactUsView />
        </Layout>
    );
};

ContactUsPage.propTypes = {
    location: object.isRequired,
};

export default ContactUsPage;

export const Head = () => <SEO tags={TAGS.CONTACT_US} />;
